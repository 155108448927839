import { Link } from "gatsby"
import React from "react"
import Logo from "../components/logo"
import styled from 'styled-components'


const Header = ({ siteName }) => (
  <header>
      <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/css/all.min.css" rel="stylesheet" type="text/css" />
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1305180558024955" crossorigin="anonymous"></script>

      <div id="top"></div>
    <Container>
      <LogoLink href="/">
       <Logo />
      </LogoLink>

      <Nav>
        <NavList> 
          <NavListItem><Link to="/" activeClassName="active">Home</Link></NavListItem>
          <NavListItem><Link to="/tips/" activeClassName="active">Tips</Link></NavListItem>
          <NavListItem><Link to="/submit-tip" activeClassName="active">Submit tip</Link></NavListItem>
          <NavListItem><Link to="/contact" activeClassName="active">Contact</Link></NavListItem>
          <NavListItem><Link to="/get-involved" activeClassName="active" className="cta">Get involved</Link></NavListItem>
        </NavList>
      </Nav>

      <SocialList>
        <SocialListItemFb>
          <a href="https://www.facebook.com/snaphints/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-square"></i>
          </a>
        </SocialListItemFb>
        <SocialListItemTw>
          <a href="https://www.twitter.com/snaphints" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter-square"></i>
           </a> 
          </SocialListItemTw>
        <SocialListItemIn>
          <a href="https://www.instagram.com/snaphints/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
           </a> 
          </SocialListItemIn>
      </SocialList>      
    </Container>
  </header>
)

const Container = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  display: flex;
  padding: 1.45rem 0;
  flex-wrap: wrap;
  justify-content: space-between;
`

const LogoLink = styled.a`
  @media screen and (max-width: 575px) {
    order: 1;
  } 
`

const Nav = styled.nav`
  margin: 0 auto;

   @media screen and (max-width: 575px) {
    order: 3;
  } 
`

const NavList = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;   
  height: 100%;
  align-items: center;
`

const NavListItem = styled.li`
  margin: 0 5px;

  a {
    color: white;
    text-decoration: none;
    padding: 0 10px;

    &:hover,
    &.active {
      color: #3c7db6;
    }

    &.cta {
      background: #3c7db6;
      color: #fff;
      padding: 5px 10px;
      border-radius: 5px;
      border: 1px solid #3c7db6;
      transition: all 0.25s;

      &:hover,
      &.active {
        color: #3c7db6;
        background: transparent;
      }
    }
  }
`

const SocialList = styled.ul`
  margin: 0;
  color: #fff;
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-size: 24px;

  a {
    color: #fff;

    &:hover {
      color: #3c7db6;
    }
  }

   @media screen and (max-width: 575px) {
    order: 2;
  } 
`

const SocialListItemFb = styled.li`
  margin-right: 10px;
`

const SocialListItemTw = styled.li`
  margin-right: 10px;
`

const SocialListItemIn = styled.li`

`

export default Header

