import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import styled from 'styled-components'
import bgimage from '../images/subscribe-bg.jpg'


export default class EmailListForm extends React.Component {
    state = {
        email: null,
    }

    _handleChange = (e) => {
        console.log({
            [`${e.target.name}`]: e.target.value,
        });
        this.setState({
            [`${e.target.name}`]: e.target.value,
        });
    }

    _handleSubmit = (e) => {
        e.preventDefault();

        console.log('submit', this.state);

        addToMailchimp(this.state.email, this.state)
            .then(({ msg, result }) => {
                console.log('msg', `${result}: ${msg}`);

                if (result !== 'success') {
                    throw msg;
                }
                alert(msg);
            })
            .catch((err) => {
                console.log('err', err);
                alert(err);
            });
    }

    render() {
        return (
            <Wrapper>
              <Container>
             <Heading>Don't miss out!</Heading>
                <Paragraph>Subscribe today to get notified when new tips are available!</Paragraph>
                    <form onSubmit={this._handleSubmit}>

                        <FormInput type="text" onChange={this._handleChange} name="FNAME" placeholder="Enter your first name" className="fname"/>
                        <FormInput type="email" onChange={this._handleChange} placeholder="Enter your email address" name="email" />
                    
                        <Submit type="submit" />
                    </form>
                </Container>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
  max-height: 400px;
  display: flex;
  margin: 10px;
  background-size: cover;
  background-position: center right;
  background-image: url(${bgimage});
`

const Container = styled.div`
  background-color: rgba(4,15,21, 0.6);
  width: 100%;
  padding: 60px 30px;

`

const Heading = styled.h2`
  color: #fff;
`

const Paragraph = styled.p`
  color: #fff;
`

const FormInput = styled.input `
    padding: 10px;
    background: transparent;
    border: 1px solid white;   
    width: 40%;
    color: #ffffff;


    &.fname {
        margin-right: 15px;
        margin-bottom: 15px;
    }

    @media(max-width: 768px) {
        width: 70%;
    }

    @media screen and (max-width: 479px) {
        width: 100%;
    }
`

const Submit = styled.input`
    background: #3c7db6;
    border: 1px solid #3c7db6;  
    padding: 10px 15px;
    margin-left: 20px;
    font-size: 16px;
    color: #fff;

    @media screen and (max-width: 479px) {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
    }
`