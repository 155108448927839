import React from "react"
import styled from 'styled-components'
import Logo from "../components/logo"
import { Link } from "gatsby"
import EmailListForm from "../components/EmailListForm"


const Footer = ({siteInfo}) => (
	<Wrapper>

		<Container>
			<FullColumn>
				<EmailListForm />
			</FullColumn>	  

			<Column>
				<Logo />
				<p className="copyright">&copy; {new Date().getFullYear()}</p>
			</Column>
			<Column>
				<p className="col-title">Links</p>
				<ul>
		          <li><Link to="/">Home</Link></li>
		          <li><Link to="/tips/">Tips</Link></li>
		          <li><Link to="/submit-tip" >Submit tip</Link></li>
		          <li><Link to="/contact">Contact</Link></li>
				</ul>
			</Column>
			<Column>
				<p className="col-title">Join the community</p>
				<ul className="social-links">
		          <li><a href="https://www.facebook.com/snaphints" target="_blank" rel="noreferrer"><i className="fab fa-facebook-square"></i><span className="hidden">facebook</span></a></li>
		          <li><a href="https://www.twitter.com/snaphints" target="_blank" rel="noreferrer"><i className="fab fa-twitter-square"></i><span className="hidden">twitter</span></a></li>
				  <li><a href="https://www.instagram.com/snaphints/" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i><span className="hidden">instagram</span></a></li>
				</ul>
			</Column>
			<Column>
				<p className="col-title">About</p>
				<p className="footer-about">{siteInfo.site.siteMetadata.footerAbout}</p>
			</Column>
		</Container>
	</Wrapper>
);



export default Footer

const Wrapper = styled.footer`
  color: white;
  padding: 10px 10px 0 10px;
`;

const Container = styled.div`
    border-top: 1px solid #3c7db6;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
   	flex-wrap: wrap;

    @media screen and (max-width: 575px) { 
    	flex-direction: column;
    }

    .copyright {
    	margin-left: 15px;
    }

    .col-title {
    	margin-top: 14px;
    	font-size: 22px;
    }

    ul {
    	list-style: none;
    	margin: 0;
    	padding: 0;

    	&.social-links {
    		display: flex;

    		a {
    			font-size: 22px;
			    background-color: #3c7db6;
			    margin-right: 10px;
			    padding: 5px 10px;
			    border-radius: 50%;

			    &:hover {
			    	color: #092536;
			    }
    		}

    		.hidden {
    			visibility:hidden;
    			text-indent: -9999px;
    			position: absolute;
    		}
    	}


    	a {
    		color: #fff;
    		text-decoration: none;
    		font-size: 16px;

    		&:hover {
    			color: #3c7db6;
    		}
    	}
    }
    
    .footer-about {
    	font-size: 16px;
    	margin-bottom: 0;
    }
`;

const FullColumn = styled.div`
	width: 100%;
`;

const Column = styled.div`
	margin: 0 40px;
	max-width: 30%;

	 @media screen and (max-width: 575px) { 
	 	max-width: 100%;
	 	margin: 10px 20px;
	 }

	&:first-child {
		margin: 0;
	}
`;