import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import Header from "./header"
import "./layout.css"
import Footer from "./footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          footerAbout
        }
      }
    }
  `);

  return (
    <>
      {<Header siteName={data.site.siteMetadata.title} />}
      <Container>
          <main>{children}</main>
          <Footer siteInfo={data} />
          <Topbtn className="top-scroller" href="#top"><i className="fas fa-angle-up"></i></Topbtn>
      </Container>
    </>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

const Container = styled.div`
  max-width: 1600px;
  margin: 0 auto;
`;

const Topbtn = styled.a`
  position: fixed;
    bottom: 20px;
    right: 20px;
    text-align: center;
    font-size: 40px;
    color: #3c7db6;
`;

export default Layout
